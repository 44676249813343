




































































































































































































































































import Vue from 'vue'

import { Affix } from 'vue-affix'
import { Pagination } from 'vuelpers'
import { Accessors } from 'vue/types/options'
import { mapGetters, mapActions } from 'vuex'
import {
	Cart,
	Config,
	CurrentOrder,
	Product,
	ProductView,
	Query,
} from '@/types'

import { getExchangedValue } from '@/mixins/UseCurrency'
import { baseProductSelectWith, cartsQuery } from '@/queries'

import UseCart from '@/mixins/UseCart'
import CartItem from '@/components/CartItem.vue'
import ProductsGroup from '@/components/ProductsGroup.vue'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'
import FormCoupon from '@/components/Forms/FormCoupon.vue'

export default Vue.extend({
	name: 'Cart',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Cart | ${APP_META.title}`,
		}
	},
	mixins: [UseCart],
	components: {
		CartItem,
		VueAffix: Affix,
		ProductsGroup,
		FormCoupon,
	},
	created() {
		if (!this.$carts.isLoaded && !this.$carts.isLoading) {
			this.getCarts(cartsQuery)
		}
		// No logged in
		else if (!this.$isLoggedIn) {
			this.$store.commit('carts/SET', {
				carts: (v: any) => {
					return { ...v, isLoading: false, isLoaded: true }
				},
			})
		}
	},
	computed: {
		...({
			...mapGetters(['$config']),
			...mapGetters('auth', ['$isLoggedIn']),
			...mapGetters('products', [
				'$recentProducts',
				'$sortedRecentProductsIds',
			]),
			...mapGetters('carts', [
				'$carts',
				'$total',
				'$subTotal',
				'$vatAndTax',
				'$cartSummary',
				'$carriage',
				'$iSource1Total',
				'$couponDAmount',
				'$currentOrder',
			]),
		} as Accessors<{
			$isLoggedIn: boolean
			$total: number
			$config?: Config
			$subTotal: number
			$vatAndTax: number
			$carts: Pagination<Cart>
			$sortedRecentProductsIds: any[]
			$recentProducts: Pagination<Product>
			$cartSummary: any
			$carriage?: number
			$iSource1Total: number
			$couponDAmount: number
			$currentOrder: CurrentOrder
		}>),
		exchangedTotal(): number {
			return getExchangedValue(this.$total)
		},
		minOrderValue(): number {
			return getExchangedValue(this.$config?.dMinimumOrderValue)
		},
		canContinue(): boolean {
			return (
				this.$total > 0 &&
				(!this.$iSource1Total || this.$iSource1Total >= this.minOrderValue)
			)
		},
	},
	methods: {
		...mapActions('carts', ['getCarts']),
		onClickPlaceOrder() {
			if (!this.canContinue) {
				return this.$toast.error(
					`Minimum order amount is ${this.minOrderValue.toFixed(2)}`,
					{
						timeout: 5000,
					}
				)
			}
			this.$router.push('/checkout')
		},
		onRevealRecentProducts(): void {
			if (this.$recentProducts.isRefetching) return
			this.$store.dispatch('products/getRecentProducts', {
				page: 1,
				perPage: 4,
				pointer: 'recent',
				with: baseProductSelectWith,
				queryArray: this.$sortedRecentProductsIds
					.map((v: ProductView) => v.id)
					.slice(0, 4),
			} as Query)
		},
	},
})
