








































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { CouponsApi } from '@/api'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { Coupon, BackendError, VFormRef, CurrentOrder } from '@/types'

export default Vue.extend({
	name: 'FormCoupon',
	data: () => ({
		vCoupon: '',
		isValid: false,
		isLoading: false,
		errors: {} as BackendError,
		rules: getValidators('required'),
	}),
	created() {
		this.vCoupon = this.$currentOrder.coupon.vCoupon || ''
	},
	computed: {
		...(mapGetters('carts', ['$currentOrder']) as Accessors<{
			$currentOrder: CurrentOrder
		}>),
		formRef(): VFormRef {
			return this.$refs.formRef as VFormRef
		},
		coupon: {
			get(): Partial<Coupon> {
				return this.$currentOrder.coupon
			},
			set(coupon: Partial<Coupon>): void {
				this.$store.commit('carts/SET', {
					'currentOrder.coupon': coupon,
				})
			},
		},
	},
	methods: {
		reInitializeCoupon() {
			this.vCoupon = ''
			this.coupon = { vCoupon: '' }
		},
		onBlur() {
			if (this.vCoupon) return
			this.onClear()
		},
		onClear() {
			this.reInitializeCoupon()
			this.formRef.reset()
		},
		onInput(v: any) {
			this.errors.vCoupon = []
			if (!v) this.reInitializeCoupon()
		},
		async onSubmit() {
			if (!this.formRef.validate()) return
			if (this.vCoupon === this.coupon.vCoupon) return

			this.isLoading = true
			const [err, res] = await CouponsApi.check({ vCoupon: this.vCoupon })
			this.isLoading = false

			if (err) {
				this.reInitializeCoupon()
				this.errors.vCoupon = ['Invalid coupon code or expired']
				return
			}

			this.coupon = res.coupon
		},
	},
})
