




















































































































import Vue from 'vue'

import { Cart } from '@/types'
import { truncate } from 'lodash'
import { mapActions } from 'vuex'

export default Vue.extend({
	name: 'CartItem',
	props: {
		cartItem: {
			required: true,
			type: Object as Vue.PropType<Cart>,
		},
	},
	computed: {
		thumbnail(): string {
			const vImage = this.cartItem?.product?.vImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
		dSellPrice(): number {
			return +(
				this.cartItem?.brand?.dSellPrice ||
				this.cartItem?.product?.dSellPrice ||
				0
			)
		},
	},
	methods: {
		...mapActions('carts', ['addToCart', 'removeCartItem']),
		truncate,
		onChangeQuantity(quantity: number) {
			this.addToCart({
				...this.cartItem,
				iQuantity: quantity,
			})
		},
	},
})
