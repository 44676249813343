























































































































import Vue from 'vue'
import { PropType } from 'vue'
import { Product, Query, Sort } from '@/types'
import { isEmpty, miniId, numberWithCommas, Pagination } from 'vuelpers'
import { isEmptyProducts } from '@/mixins/UseProducts'
import { useSessionStorage } from '@/composables/useSessionStorage'
export default Vue.extend({
	name: 'ProductsGroup',
	props: {
		id: {
			type: String as PropType<string>,
			default: miniId(),
		},
		title: {
			type: String as PropType<string>,
		},
		fetchFunction: {
			type: Function as PropType<(query: Query) => Promise<any>>,
			required: true,
		},
		provider: {
			required: true,
			type: Object as PropType<
				Pagination<Product> & {
					phase?: number
					querySql?: string
					sqlQueryArray: any[]
				}
			>,
		},
		limit: {
			type: Number as PropType<number>,
			default: Infinity,
		},
		hideSort: {
			default: true,
			type: Boolean as PropType<boolean>,
		},
		hideViewAll: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		showCount: {
			default: false,
			type: Boolean as PropType<boolean>,
		},
		paginate: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		infiniteScroll: {
			default: true,
			type: Boolean as PropType<boolean>,
		},
	},
	data() {
		const [sortOrder, syncSortOrder] = useSessionStorage<Sort>(
			'products_group_sort_order',
			{}
		)
		return {
			sortOrder,
			syncSortOrder,
		}
	},
	created() {
		if (!isEmpty(this.sortOrder)) {
			this.onInputSortOrder()
		}
	},
	computed: {
		isEmptyProducts(): boolean {
			return isEmptyProducts(this.provider)
		},
		count(): string {
			return numberWithCommas(this.provider.total)
		},
	},
	methods: {
		onIntersect(next: any) {
			return (_: any, __: any, isIntersecting: boolean) => {
				if (isIntersecting) next()
			}
		},
		onInputSortOrder() {
			console.log('onInputSortOrder')
			this.syncSortOrder(this.sortOrder)
			this.fetchFunction({
				page: 1,
				force: true,
				replace: true,
				paginate: true,
				...this.sortOrder,
			})
		},
		onRefetchProducts() {
			if (!this.infiniteScroll) return

			console.log('onRefetchProducts')
			this.fetchFunction({
				force: true,
				paginate: true,
				...this.sortOrder,
			})
		},
	},
})
